import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const ContactInfo = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm()
  const {
    response: { info },
    loading,
    error,
  } = useQuery('contactinfos')

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('contactinfos/store', values)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Contact Info</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="phone">Ygn Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    {...register('phone', {
                      required: 'Ygn Phone is required',
                    })}
                    defaultValue={info?.phone}
                  />
                  {errors.phone && (
                    <span className="text-danger">
                      {errors?.phone?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="mdy_phone">Mdy Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_phone"
                    {...register('mdy_phone', {
                      required: 'Mdy Phone is required',
                    })}
                    defaultValue={info?.mdy_phone}
                  />
                  {errors.mdy_phone && (
                    <span className="text-danger">
                      {errors?.mdy_phone?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="viber">Ygn Viber Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="viber"
                    {...register('viber', {
                      required: 'Ygn Viber Number is required',
                    })}
                    defaultValue={info?.viber}
                  />
                  {errors.viber && (
                    <span className="text-danger">
                      {errors?.viber?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="mdy_viber">Mdy Viber Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_viber"
                    {...register('mdy_viber', {
                      required: 'Mdy Viber Number is required',
                    })}
                    defaultValue={info?.mdy_viber}
                  />
                  {errors.mdy_viber && (
                    <span className="text-danger">
                      {errors?.mdy_viber?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="telegram">Ygn Telegram Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="telegram"
                    {...register('telegram', {
                      required: 'Ygn Telegram Number is required',
                    })}
                    defaultValue={info?.telegram}
                  />
                  {errors.telegram && (
                    <span className="text-danger">
                      {errors?.telegram?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="mdy_telegram">Mdy Telegram Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_telegram"
                    {...register('mdy_telegram', {
                      required: 'Mdy Telegram Number is required',
                    })}
                    defaultValue={info?.mdy_telegram}
                  />
                  {errors.mdy_telegram && (
                    <span className="text-danger">
                      {errors?.mdy_telegram?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="facebook">Ygn Facebook</label>
                  <input
                    type="text"
                    className="form-control"
                    id="facebook"
                    {...register('facebook', {
                      required: 'Ygn Facebook is required',
                    })}
                    defaultValue={info?.facebook}
                  />
                  {errors.facebook && (
                    <span className="text-danger">
                      {errors?.facebook?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="mdy_facebook">Mdy Facebook</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_facebook"
                    {...register('mdy_facebook', {
                      required: 'Mdy Facebook is required',
                    })}
                    defaultValue={info?.mdy_facebook}
                  />
                  {errors.mdy_facebook && (
                    <span className="text-danger">
                      {errors?.mdy_facebook?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="email">Ygn Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    {...register('email', {
                      required: 'Ygn Email is required',
                    })}
                    defaultValue={info?.email}
                  />
                  {errors.email && (
                    <span className="text-danger">
                      {errors?.email?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="mdy_email">Mdy Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_email"
                    {...register('mdy_email', {
                      required: 'Mdy Email is required',
                    })}
                    defaultValue={info?.mdy_email}
                  />
                  {errors.mdy_email && (
                    <span className="text-danger">
                      {errors?.mdy_email?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="website">Ygn Website</label>
                  <input
                    type="text"
                    className="form-control"
                    id="website"
                    {...register('website', {
                      required: 'Ygn Website is required',
                    })}
                    defaultValue={info?.website}
                  />
                  {errors.website && (
                    <span className="text-danger">
                      {errors?.website?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="mdy_website">Mdy Website</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mdy_website"
                    {...register('mdy_website', {
                      required: 'Mdy Website is required',
                    })}
                    defaultValue={info?.mdy_website}
                  />
                  {errors.mdy_website && (
                    <span className="text-danger">
                      {errors?.mdy_website?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="address">Ygn Address</label>
                  <textarea
                    id="address"
                    className="form-control"
                    placeholder="Write  your address here ..."
                    rows="5"
                    {...register('address', {
                      required: 'Ygn Address is required',
                    })}
                    defaultValue={info?.address}
                  />
                  {errors.address && (
                    <span className="text-danger">
                      {errors?.address?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="mdy_address">Mdy Address</label>
                  <textarea
                    id="mdy_address"
                    className="form-control"
                    placeholder="Write  your address here ..."
                    rows="5"
                    {...register('mdy_address', {
                      required: 'Mdy Address is required',
                    })}
                    defaultValue={info?.mdy_address}
                  />
                  {errors.mdy_address && (
                    <span className="text-danger">
                      {errors?.mdy_address?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    {info.phone ? 'Edit' : 'Save'}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default ContactInfo
