import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Table,
  Button,
  CardFooter,
  Col,
} from 'reactstrap'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import CustomPagnation from 'components/pagination'
import Select from 'react-select'
import { paginationLimit } from 'variables/limits'
import ExcelExport from 'components/excelExport'
import { NotificationManager } from 'react-notifications'
import { sendData } from 'utli/apiService'

function ProductList() {
  const router = useHistory()
  const [exportedData, setExportedData] = useState([])
  const [exportedLoading, setExportedLoading] = useState(false)
  const query = queryString.parse(router.location.search)
  let url = 'products?'
  let page = query.page || 1
  if (query.search) url += `&keyword=${query.search}`
  if (query.category_id) url += `&category_id=${query.category_id}`
  if (query.is_top_sale) url += `&is_top_sale=${query.is_top_sale}`
  if (query.is_new) url += `&is_new=${query.is_new}`
  if (query.page) url += `&page=${page}`

  const {
    response: { productlist },
    loading,
    error,
  } = useQuery(url)

  const {
    response: { data: categorylists },
    loading: categoryLoading,
    error: categoryErr,
  } = useQuery('categories/getList')
  const categories = categorylists?.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const getExportedData = async () => {
    setExportedLoading(true)
    const products = [...productlist.data]
    setExportedData(
      products.map((element) => {
        element.category = element.category?.name
        return element
      })
    )
    setExportedLoading(false)
  }

  const topSale = async (id) => {
    const response = await sendData('products/isTopSale', {
      id,
    })
    if (response) {
      NotificationManager.success('Updated')
      window.location.reload()
    }
  }

  const updateNew = async (id) => {
    const response = await sendData('products/isNew', {
      id,
    })
    if (response) {
      NotificationManager.success('Updated')
      window.location.reload()
    }
  }

  if (loading | categoryLoading) return <h3>Loading...</h3>
  if (error | categoryErr) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardTitle className="pt-3 pl-4">
        <div className="d-flex justify-content-between pr-3">
          <h2>Products</h2>
          <span>Total {productlist.total} products</span>
        </div>

        <Row className="mt-3 pl-2 pr-2">
          <Col md="4" sm="4">
            <label>Category</label>
            <Select
              classNamePrefix="addl-class"
              options={categories}
              defaultValue={categories[0]?.value}
              onChange={({ value }) => {
                router.push(`${router.location.pathname}?category_id=${value}`)
              }}
            />
          </Col>
          <Col md="4" sm="4"></Col>
          <Col md="4" sm="3">
            <br />
            <Button className="mt-2" color="success" onClick={getExportedData}>
              <span className="btn-inner--text">
                {exportedLoading ? 'Loading..' : 'Export Excel'}
              </span>
            </Button>

            <Button
              href={`${router.location.pathname}/create`}
              className="mt-2"
              color="primary"
            >
              Create New
            </Button>
            <Button
              href={`${router.location.pathname}`}
              className="mt-2"
              color="primary"
            >
              View All
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 pl-2 pr-2">
          <Col>
            <Button
              className="m-1"
              color="primary"
              onClick={() => {
                router.push(`${router.location.pathname}?is_top_sale=1`)
              }}
            >
              Top Sale
            </Button>
            <Button
              className="m-1"
              color="primary"
              onClick={() => {
                router.push(`${router.location.pathname}?is_new=1`)
              }}
            >
              Latest
            </Button>
          </Col>
        </Row>
      </CardTitle>

      <CardBody>
        <Row className="justify-content-md-sstart justify-content-center">
          <div className="d-flex justify-content-end">
            {exportedData.length > 0 && (
              <ExcelExport
                data={exportedData}
                fileName={'productList'}
                columns={[
                  { title: 'Name', value: 'name' },
                  { title: '1 Pc Price', value: 'base_price' },
                  { title: '10 Pc Price', value: 'ten_price' },
                  { title: '50 Pc Price', value: 'fifty_price' },
                  { title: 'above 50 Pc Price', value: 'above_fifty_price' },
                  { title: 'Length', value: 'length' },
                  { title: 'Height', value: 'height' },
                  { title: 'Width', value: 'width' },
                  { title: 'Thinkness', value: 'thickness' },
                  { title: 'Color', value: 'color' },
                ]}
                customButton={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel ({exportedData.length})
                  </Button>
                }
              />
            )}
          </div>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Images</th>
                <th>Name</th>
                <th>Category</th>
                <th>Discount</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {productlist.data.map((element, index) => (
                <tr key={index}>
                  <td>
                    {' '}
                    {index + 1 + page * paginationLimit - paginationLimit}
                  </td>
                  <td>
                    <img
                      alt="product list"
                      src={element.productimages[0].image}
                      width="70"
                      height="70"
                    />
                  </td>
                  <td> {element.name}</td>
                  <td>{element.category.name}</td>

                  {Number(element.is_discount) === 1 ? (
                    <td>
                      <span className="badge badge-success">Yes</span>
                    </td>
                  ) : (
                    <td>
                      <span className="badge badge-danger">No</span>
                    </td>
                  )}

                  <td>
                    <Button
                      color="primary"
                      className="btn btn-sm"
                      onClick={() => {
                        router.push(`product/edit/${element.id}`)
                      }}
                    >
                      View
                    </Button>
                    <Button
                      color="success"
                      className="btn btn-sm"
                      onClick={() => topSale(element.id)}
                    >
                      Top Sale
                    </Button>
                    <Button
                      color="info"
                      className="btn btn-sm"
                      onClick={() => updateNew(element.id)}
                    >
                      Latest
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </CardBody>
      {productlist.total > 10 && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation
            pageCount={productlist.total / paginationLimit}
          ></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}
export default ProductList
