import React from 'react'
import { Card, CardBody, CardTitle, Row, Col, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
const RoyalCustomer = () => {
  const headers = ['#', 'User Name', 'Order Count']
  let url = 'report/bestCustomer?'
  const {
    response: { reportList },
    loading,
    error,
  } = useQuery(url)

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">User Report</h3>
              </Col>
            </Row>
          </CardTitle>
          <Table responsive className="items-center">
            <thead>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {reportList.map((e, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <Link to={`/user/edit/${e.id}`}>{e.name}</Link>
                  </td>
                  <td>{e.orders_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
export default RoyalCustomer
