import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { getReadableDateDisplay } from 'utli/convertToHumanReadableTime'
import CustomPagnation from 'components/pagination'
import useQuery from 'hooks/useQuery'
import { paginationLimit } from 'variables/limits'
import ExcelExport from 'components/excelExport'
const Orders = () => {
  const router = useHistory()
  const headers = [
    '#',
    'User',
    'User Type',
    'Address',
    'Payment Method',
    'Delivery Method',
    'Date',
    'Items',
    'Status',
    'Note',
    'Actions',
  ]
  const [exportedData, setExportedData] = useState([])
  const [exportedLoading, setExportedLoading] = useState(false)
  let url = 'orders/get?'

  const query = queryString.parse(router.location.search)
  let page = query.page || 1
  if (query.status) url += `status=${query.status}`
  if (query.page) url += `&page=${page}`
  const {
    response: { orderlist },
    loading,
    error,
  } = useQuery(url)

  const getOrderStatus = (status) => {
    switch (parseInt(status)) {
      case 1:
        return <span className="text-info">Pending</span>
      case 2:
        return <span className="text-success">Delivery Pending</span>
      case 3:
        return <span className="text-danger">Cancel</span>
      case 4:
        return <span className="text-success">Delivery Done</span>
      default:
        return <span></span>
    }
  }

  const getExportedData = async () => {
    setExportedLoading(true)
    const orders = [...orderlist.data]
    setExportedData(
      orders.map((element) => {
        element.name = element.user?.name
        // element.qty = element.orderdetails?.qty;
        element.date = getReadableDateDisplay(element.created_at)
        element.item = element.orderdetails.length
        return element
      })
    )
    setExportedLoading(false)
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Orders</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  onClick={getExportedData}
                >
                  <span className="btn-inner--text">
                    {exportedLoading ? 'Loading..' : 'Export Excel'}
                  </span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="info"
                  size="sm"
                  href={`${router.location.pathname}?status=1`}
                >
                  <span className="btn-inner--text">Pending</span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}?status=2`}
                >
                  <span className="btn-inner--text">Confirm</span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="danger"
                  size="sm"
                  href={`${router.location.pathname}?status=3`}
                >
                  <span className="btn-inner--text">Cancel</span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}?status=4`}
                >
                  <span className="btn-inner--text">Delivery Done</span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <div className="d-flex justify-content-end">
            {exportedData.length > 0 && (
              <ExcelExport
                data={exportedData}
                fileName={'orderList'}
                columns={[
                  { title: 'User', value: 'name' },
                  { title: 'Date', value: 'date' },
                  { title: 'Item', value: 'item' },
                  { title: 'Status', value: 'status' },
                ]}
                customButton={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel({exportedData.length})
                  </Button>
                }
              />
            )}
            <span>Total {orderlist.total} orders</span>
          </div>
          <Table responsive className="items-center">
            <thead>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {orderlist.data.map((element, index) => {
                return (
                  <tr key={`user-account-list-${element.id}`}>
                    <td>
                      {' '}
                      {index + 1 + page * paginationLimit - paginationLimit}
                    </td>
                    {element.is_guest === '0' && <td>{element.user?.name}</td>}
                    {element.is_guest === '1' && (
                      <td>
                        {element.guest_name} ({element.guest_phone})
                      </td>
                    )}
                    <td>{element.is_guest === '0' ? 'Member' : 'Guest'}</td>
                    <td>
                      {element.is_guest === '0'
                        ? element.user?.address
                        : element.guest_address}
                    </td>
                    <td>
                      {element.cash_on_deli === '0'
                        ? 'Banking'
                        : 'Cash On Deli'}
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {element.delivery_method}
                    </td>
                    <td>{getReadableDateDisplay(element.created_at)}</td>
                    <td>{element.orderdetails.length}</td>
                    <td>{getOrderStatus(element.status)}</td>
                    <td>{element.note}</td>
                    <td>
                      <div>
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="success"
                          onClick={() => {
                            router.push(`/order/edit/${element.id}`)
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-pen" />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
        {orderlist.total > paginationLimit && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation
              pageCount={orderlist.total / paginationLimit}
            ></CustomPagnation>
          </CardFooter>
        )}
      </Card>
    </>
  )
}
export default Orders
