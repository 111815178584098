import { useState, useRef } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const EditNotification = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const {
    response: { notiInfo },
    loading,
    error,
  } = useQuery(`notifications/detail/${props.match.params.id}`)
  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const submitData = async (values) => {
    const formData = new FormData()
    const imageFile = await dataUrlToFile(cropImage)
    formData.append('title', values.title)
    formData.append('body', values.body)
    formData.append('image', imageFile)
    formData.append('id', props.match.params.id)
    if (cropImage) {
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('image', imageFile)
    }
    const response = await sendData('notifications/update', formData)
    if (response) {
      NotificationManager.success('Save')
      setInitState({ ...initState, croppedImage: null })
      setCropImage(null)
      window.location.reload()
    }
  }
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <Form onSubmit={handleSubmit(submitData)}>
          <CardBody>
            <Row>
              <Col md="6">
                <CardTitle className="d-flex justify-content-between">
                  <h2 className="mt-2">Edit Annocument</h2>
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('title', {
                          required: 'Title is required',
                        })}
                        defaultValue={notiInfo.title}
                      ></input>
                      {errors.title && (
                        <span className="text-danger">
                          {errors?.title?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="submit-form">
                      <label>Body</label>
                      <textarea
                        className="form-control"
                        placeholder="Write  text here ..."
                        rows="5"
                        {...register('body', {
                          required: 'Body is required',
                        })}
                        defaultValue={notiInfo.body}
                      />
                      {errors.body && (
                        <span className="text-danger">
                          {errors?.body?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <FormGroup>
                    <label>Image</label>

                    {cropImage ? (
                      <div>
                        <img
                          alt="placeholder containeer"
                          src={cropImage}
                          width="400"
                          height="400"
                          onClick={() => {
                            inputFile.current.click()
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="category result"
                          src={notiInfo.image}
                          width="400"
                          height="400"
                          onClick={() => {
                            inputFile.current.click()
                          }}
                        />
                      </div>
                    )}
                    {initState.imageError && (
                      <p className="text-danger">Image is required</p>
                    )}
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        if (event.target.files[0]) {
                          setInitState({
                            ...initState,
                            openCropper: true,
                            image: URL.createObjectURL(event.target.files[0]),
                          })
                        }
                      }}
                    />
                  </FormGroup>
                </Row>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Save
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Form>
        {initState.openCropper && (
          <CropperModal
            isShow={initState.openCropper}
            image={initState.image}
            close={() => setInitState({ ...initState, openCropper: false })}
            setCroppedImage={async (image) => {
              setCropImage(image)
            }}
          />
        )}
      </Card>
    </>
  )
}
export default EditNotification
