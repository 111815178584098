import React, { useState, useEffect } from 'react'
import {
  CardHeader,
  Card,
  CardBody,
  Table,
  CardFooter,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from 'reactstrap'
import useQuery from 'hooks/useQuery'
import { NotificationManager } from 'react-notifications'
import { sendData } from 'utli/apiService'
import { useForm } from 'react-hook-form'
function EditOrder(props) {
  const [cancelOrderModal, setCancelOrderModal] = useState(false)
  const [deliveriedOrderModal, setDeliveriedOrderModal] = useState(false)

  const {
    response: { orderInfo },
    loading,
    error,
  } = useQuery(`orders/detail/${props.match.params.id}`)

  const {
    register: cancelOrderRegister,
    handleSubmit: cancelOrderHandleSubmit,
    formState: { errors: cancelOrderErrors },
  } = useForm()

  const {
    register: deliveriedRegister,
    handleSubmit: deliveriedHandleSubmit,
    formState: { errors: deliveriedErrors },
  } = useForm()

  const [orders, setOrders] = useState([])
  const [initState, setInitState] = useState({
    loading: false,
  })

  const getTotal = () => {
    let total = 0
    orders.forEach(
      (element) => (total += getPrice(element, element.qty) * element.qty)
    )
    return total
  }
  const validate = () => {
    let valid = true
    orders.forEach((element) => {
      if (element.qty.length === 0) valid = false
    })
    return valid
  }
  const getDeleteIDs = () => {
    let deletedIDs = []
    orderInfo.order_details.forEach((element) => {
      if (!orders.find((order) => order.id === element.id)) {
        deletedIDs.push({ detail_id: element.id })
      }
    })
    return deletedIDs
  }
  const confirmOrder = async () => {
    if (validate()) {
      const response = await sendData('orders/updateOrder', {
        order_id: props.match.params.id,
        cart: orders.map((element) => ({
          product_id: element.product.id,
          qty: element.qty,
          detail_id: element.id,
        })),
        deletecart: getDeleteIDs(),
      })
      if (response) {
        NotificationManager.success('Updated')
        window.location.reload()
      }
    } else {
      NotificationManager.error('Invalid fields')
    }
  }

  const deliverOrder = async (values) => {
    const response = await sendData('orders/deliver', {
      id: props.match.params.id,
      delivery_name: values.delivery_name,
      delivery_fee: values.delivery_fee,
      delivery_phone: values.delivery_phone,
    })
    if (response) {
      NotificationManager.success('Updated')
      window.location.reload()
    }
  }

  const deleteData = (id) => {
    setOrders(orders.filter((element) => element.id !== id))
  }

  const cancelOrder = async (values) => {
    setInitState({ ...initState, loading: true })
    const response = await sendData('orders/cancel', {
      id: props.match.params.id,
      delete_comment: values.delete_comment,
    })
    if (response) {
      setInitState({ ...initState, loading: false })
      NotificationManager.success('Updated')
      window.location.reload()
    }
  }

  useEffect(() => {
    if (!loading) {
      setOrders(orderInfo.order_details)
    }
  }, [loading, orderInfo])

  const getPrice = (element, qty) => {
    if (qty < Number(element.product.pcs_per_mc)) {
      return element.product.base_price;
    }
    else {
      const mc = qty / Number(element.product.pcs_per_mc);
      if (mc <= 10) {
        return element.product.ten_price;
      }
      else if (mc <= 50) {
        return element.product.fifty_price;
      }
      else if (mc > 50) {
        return element.product.above_fifty_price;
      }
    }


  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <div>
      <Card>
        <CardHeader className="bg-default pl-4 pr-4">
          <h4 className="text-white">Order Info</h4>
          <div className="d-flex justify-content-between">
            <h5 className="text-white">
              Username :{' '}
              {orderInfo.is_guest === '0'
                ? orderInfo.user.name
                : orderInfo.guest_name}
            </h5>
            <h5 className="text-white">
              Phone :{' '}
              {orderInfo.is_guest === '0'
                ? orderInfo.user.phone
                : orderInfo.guest_phone}
            </h5>
          </div>
          <div className="d-flex justify-content-end">
            <h5 className="text-white">
              Date :{orderInfo.created_at.substr(0, 10)}
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <td>No</td>
                <td style={{ width: '30%' }}>Product</td>
                <td>Price</td>
                <td style={{ width: '25%' }}>Qty</td>
                <td className="d-flex justify-content-end">Total</td>
              </tr>
            </thead>
            <tbody>
              {orders.map((element, index) => (
                <tr key={`order-count-${index}`}>
                  <td>{index + 1}</td>
                  <td>{element.product.name}</td>
                  <td>{getPrice(element, element.qty)}</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <input
                        className="form-control"
                        style={{ width: '60%' }}
                        type="number"
                        value={element.qty}
                        min={1}
                        onChange={(e) => {
                          let newArr = [...orders]
                          newArr[index].qty = e.target.value
                          setOrders(newArr)
                        }}
                      />
                      <Button
                        className="btn-sm btn-danger"
                        onClick={() => deleteData(element.id)}
                      >
                        X
                      </Button>
                    </div>
                  </td>
                  <td className="d-flex justify-content-end">
                    {getPrice(element, element.qty) * element.qty}
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan="4"></td>
                <td className="d-flex justify-content-end">{getTotal()}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          {parseInt(orderInfo.status) === 1 && (
            <>
              <Button
                className="btn-sm bg-success text-white"
                onClick={confirmOrder}
                disabled={initState.loading}
              >
                Confirm
              </Button>

              <Button
                className="btn-sm bg-danger text-white"
                onClick={() => setCancelOrderModal(true)}
                disabled={initState.loading}
              >
                Cancel
              </Button>
            </>
          )}
          {parseInt(orderInfo.status) === 2 && (
            <>
              <h4 className="text-info mr-3">Status : Delivery Pending</h4>
              <Button
                className="btn-sm bg-success text-white"
                onClick={() => setDeliveriedOrderModal(true)}
                disabled={initState.loading}
              >
                Deliver
              </Button>
            </>
          )}
          {parseInt(orderInfo.status) === 3 && (
            <div>
              <h4>Delete Comment: {orderInfo?.delete_comment}</h4>
              <h4 className="text-danger">Status : Cancel</h4>
            </div>
          )}{' '}
          {parseInt(orderInfo.status) === 4 && (
            <div>
              <h4>
                Delivery company or delivery man or car gate:{' '}
                {orderInfo?.delivery_name}
              </h4>
              <h4>Delivery fee: {orderInfo?.delivery_fee}</h4>
              <h4>Delivery phone: {orderInfo?.delivery_phone}</h4>
              <h4 className="text-success">Status : Delivery Done</h4>
            </div>
          )}
        </CardFooter>
      </Card>

      {/* cancel order form */}
      <Modal className="modal-dialog-centered" isOpen={cancelOrderModal}>
        <div className="modal-header">
          <h5 className="modal-title">Cancel Order</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setCancelOrderModal(false)
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form onSubmit={cancelOrderHandleSubmit(cancelOrder)}>
          <div className="modal-body">
            <Row>
              <Col md="12" sm="12">
                <label>Delete Comment</label>
                <input
                  className="form-control"
                  {...cancelOrderRegister('delete_comment', {
                    required: 'Delete Comment is required',
                  })}
                />
                {cancelOrderErrors.delete_comment && (
                  <span className="text-danger">
                    {cancelOrderErrors?.delete_comment?.message}
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="default" type="submit">
              OK
            </Button>
          </div>
        </Form>
      </Modal>

      {/* deliveried order form */}
      <Modal className="modal-dialog-centered" isOpen={deliveriedOrderModal}>
        <div className="modal-header">
          <h5 className="modal-title">Deliveried Order</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setDeliveriedOrderModal(false)
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form onSubmit={deliveriedHandleSubmit(deliverOrder)}>
          <div className="modal-body">
            <Row>
              <Col md="12" sm="12">
                <label>Delivery company or Delivery man or Car gate</label>
                <input
                  className="form-control"
                  {...deliveriedRegister('delivery_name', {
                    required:
                      'Delivery company or Delivery man or Car gate is required',
                  })}
                />
                {deliveriedErrors.delivery_name && (
                  <span className="text-danger">
                    {deliveriedErrors?.delivery_name?.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <label>Delivery Fee</label>
                <input
                  className="form-control"
                  {...deliveriedRegister('delivery_fee', {
                    required: 'Delivery Fee is required',
                  })}
                />
                {deliveriedErrors.delivery_fee && (
                  <span className="text-danger">
                    {deliveriedErrors?.delivery_fee?.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <label>Delivery Phone</label>
                <input
                  className="form-control"
                  {...deliveriedRegister('delivery_phone', {
                    required: 'Delivery Phone is required',
                  })}
                />
                {deliveriedErrors.delivery_phone && (
                  <span className="text-danger">
                    {deliveriedErrors?.delivery_phone?.message}
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button color="default" type="submit">
              OK
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditOrder
