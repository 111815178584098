import React, { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'
import draftToHtml from 'draftjs-to-html'
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import useQuery from 'hooks/useQuery'
import { useForm, Controller } from 'react-hook-form'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './edit.scss'
const EditProduct = (props) => {
  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImage: null,
    submitLoading: false,
    imageError: false,
    isEdit: false,
    editImageID: null,
    description: '',
  })

  const inputFile = useRef(null)
  const router = useHistory()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const converttoHtml = (value) => {
    if (typeof value === 'object') {
      const rawContentState = convertToRaw(value.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      return markup
    } else {
      return value
    }
  }

  const {
    response: { categorylists },
    loading,
    error,
  } = useQuery('categories')
  const categories = categorylists?.data.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const {
    response: { productinfo },
    loading: productLoading,
    error: productLoadingErr,
  } = useQuery(`products/detail/${props.match.params.id}`)

  React.useEffect(() => {
    setInitState({
      ...initState,
      description: converttoHtml(productinfo?.description),
    })
  }, [productinfo])

  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('base_price', values.base_price)
    formData.append('discount_base_price', values.discount_base_price)
    formData.append('ten_price', values.ten_price)
    formData.append('discount_ten_price', values.discount_ten_price)
    formData.append('fifty_price', values.fifty_price)
    formData.append('discount_fifty_price', values.discount_fifty_price)
    formData.append('above_fifty_price', values.above_fifty_price)
    formData.append('discount_above_fifty_price', values.discount_above_fifty_price)
    formData.append('width', values.width)
    formData.append('height', values.height)
    formData.append('length', values.length)
    formData.append('thickness', values.thickness)
    formData.append('code', values.code)
    formData.append('description', initState.description)
    formData.append('category_id', values.category_id)
    formData.append('color', values.color)
    formData.append('is_discount', values.is_discount ? 1 : 0)
    formData.append('pcs_per_mc', values.pcs_per_mc)
    formData.append('id', props.match.params.id)
    const response = await sendData('products/update', formData)
    if (response) {
      NotificationManager.success('Save')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }
  const uploadImage = async (image) => {
    setInitState({ ...initState, submitLoading: true })
    const formData = new FormData()
    const imageFile = await dataUrlToFile(image)
    formData.append('image', imageFile)
    let urlType = initState.isEdit
      ? `updateImage/${initState.editImageID}`
      : `saveimage/${props.match.params.id}`
    const response = await sendData(`products/${urlType}`, formData)
    if (response) {
      NotificationManager.success('Uploaded')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const deleteImage = async ({ id }) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData(`products/deleteimage/${id}`)
    if (response) {
      NotificationManager.success('Deleted')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const updateImage = async (element) => {
    setInitState({ ...initState, isEdit: true, editImageID: element.id })
    inputFile.current.click()
  }

  const deleteProduct = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.Data cannot be recovered',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(
              `products/delete/${props.match.params.id}`
            )
            if (response) {
              router.replace('/product')
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading || productLoading) return <h3>Loading...</h3>
  if (error || productLoadingErr) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">Edit Product</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  defaultValue={productinfo?.name}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('code', {
                    required: 'Code is required',
                  })}
                  defaultValue={productinfo?.code}
                />
                {errors.code && (
                  <span className="text-danger">{errors?.code?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label className="mt-4">Category</label>
                <Controller
                  control={control}
                  name="category_id"
                  rules={{ required: true }}
                  defaultValue={parseInt(productinfo.category_id)}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={categories}
                      defaultValue={parseInt(productinfo.category_id)}
                      value={categories.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />
                {errors.category_id && (
                  <span className="text-danger">
                    {errors?.category_id?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>1Pcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('base_price', {
                    required: '1Pcs is required',
                  })}
                  defaultValue={productinfo?.base_price}
                />
                {errors.base_price && (
                  <span className="text-danger">
                    {errors?.base_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Discount 1Pcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('discount_base_price', {
                    required: 'Discount 1Pcs is required',
                  })}
                  defaultValue={productinfo?.discount_base_price}
                />
                {errors.discount_base_price && (
                  <span className="text-danger">
                    {errors?.discount_base_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>1-10Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('ten_price', {
                    required: '1-10Mcs is required',
                  })}
                  defaultValue={productinfo?.ten_price}
                />
                {errors.ten_price && (
                  <span className="text-danger">
                    {errors?.ten_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Discount 1-10Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('discount_ten_price', {
                    required: 'Discount 1-10Mcs is required',
                  })}
                  defaultValue={productinfo?.discount_ten_price}
                />
                {errors.discount_ten_price && (
                  <span className="text-danger">
                    {errors?.discount_ten_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>11-50Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('fifty_price', {
                    required: '11-50Mcs is required',
                  })}
                  defaultValue={productinfo?.fifty_price}
                />
                {errors.fifty_price && (
                  <span className="text-danger">
                    {errors?.fifty_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Discount 11-50Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('discount_fifty_price', {
                    required: 'Discount 11-50Mcs is required',
                  })}
                  defaultValue={productinfo?.discount_fifty_price}
                />
                {errors.discount_fifty_price && (
                  <span className="text-danger">
                    {errors?.discount_fifty_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Above 50Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('above_fifty_price', {
                    required: 'Above 50Mcs is required',
                  })}
                  defaultValue={productinfo?.above_fifty_price}
                />
                {errors.above_fifty_price && (
                  <span className="text-danger">
                    {errors?.above_fifty_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Discount Above 50Mcs</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('discount_above_fifty_price', {
                    required: 'Discount Above 50Mcs is required',
                  })}
                  defaultValue={productinfo?.discount_above_fifty_price}
                />
                {errors.discount_above_fifty_price && (
                  <span className="text-danger">
                    {errors?.discount_above_fifty_price?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>PCS Per Mc</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('pcs_per_mc', {
                    required: 'PCS Per Mc is required',
                  })}
                  defaultValue={productinfo?.pcs_per_mc}
                />
                {errors.pcs_per_mc && (
                  <span className="text-danger">
                    {errors?.pcs_per_mc?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Width</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('width', {
                    required: 'Width is required',
                  })}
                  defaultValue={productinfo?.width}
                />
                {errors.width && (
                  <span className="text-danger">{errors?.width?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Height</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('height', {
                    required: 'Height is required',
                  })}
                  defaultValue={productinfo?.height}
                />
                {errors.height && (
                  <span className="text-danger">{errors?.height?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Length</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('length', {
                    required: 'Length is required',
                  })}
                  defaultValue={productinfo?.length}
                />
                {errors.length && (
                  <span className="text-danger">{errors?.length?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Thickness</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('thickness', {
                    required: 'Thickness is required',
                  })}
                  defaultValue={productinfo?.thickness}
                />
                {errors.thickness && (
                  <span className="text-danger">
                    {errors?.thickness?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label>Colour</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('color', {
                    required: 'Colour is required',
                  })}
                  defaultValue={productinfo?.color}
                />
                {errors.color && (
                  <span className="text-danger">{errors?.color?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="mt-3">
                <label className="mr-3">Discount</label>
                <input
                  type="checkbox"
                  defaultChecked={Number(productinfo?.is_discount) === 1}
                  {...register('is_discount')}
                />
              </Col>
              <Col sm="12" className="mt-3 editor-main">
                <label>Description</label>
                <Editor
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                  defaultEditorState={getDraft(productinfo.description)}
                  toolbar={{ options: ['list'] }}
                  name="coordination"
                  onEditorStateChange={(editorState) => {
                    const rawContentState = convertToRaw(
                      editorState.getCurrentContent()
                    )
                    const markup = draftToHtml(rawContentState)
                    setInitState({ ...initState, description: markup })
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button
              color="default"
              type="submit"
              disabled={initState.submitLoading}
            >
              Edit
            </Button>
            <Button
              color="danger"
              disabled={initState.submitLoading}
              onClick={deleteProduct}
            >
              Delete
            </Button>
          </CardFooter>
        </Card>
      </Form>
      <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Edit Images</h3>
        </CardTitle>
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => {
            setInitState({ ...initState, isEdit: false })
            inputFile.current.click()
          }}
          style={{
            width: 200,
            height: 200,
            background: 'grey',
            cursor: 'pointer',
            marginLeft: '45%',
            fontSize: 70,
            color: 'white',
          }}
        >
          +
        </div>
        <CardBody className="d-flex d-flex-wrap" style={{ overflow: 'auto' }}>
          {productinfo?.productimages?.map((element, index) => (
            <div className="img-container" key={`produut-image-${index}`}>
              <img
                width="200"
                height="200"
                src={element.image}
                alt="edited result"
                onClick={() => updateImage(element)}
              />
              {index > 0 && (
                <span
                  className="btn-inner--icon"
                  onClick={() => deleteImage(element)}
                >
                  <i className="fas fa-trash text-danger" />
                </span>
              )}
            </div>
          ))}

          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={(event) => {
              setInitState({
                ...initState,
                openCropper: true,
                image: URL.createObjectURL(event.target.files[0]),
              })
            }}
          />
        </CardBody>
      </Card>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={(image) => uploadImage(image)}
          x={2}
          y={2}
        />
      )}
    </Container>
  )
}
export default EditProduct
