import { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import useQuery from 'hooks/useQuery'
const EditCategory = (props) => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm()
  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })
  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const submitData = async (values) => {
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('id', props.match.params.id)
    if (cropImage) {
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('image', imageFile)
    }
    const response = await sendData('services/update', formData)
    if (response) {
      NotificationManager.success('Save')
      setInitState({ ...initState, croppedImage: null })
      setCropImage(null)
      window.location.reload()
    }
  }

  const {
    response: { serviceinfo },
    loading,
    error,
  } = useQuery(`services/detail/${props.match.params.id}`)

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">Edit Service</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  defaultValue={serviceinfo.name}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="pt-3">
                <label>Image</label>
                {cropImage ? (
                  <div>
                    <img
                      alt="placeholder containeer"
                      src={cropImage}
                      width="200"
                      height="200"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="category result"
                      src={serviceinfo.image}
                      width="200"
                      height="200"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  </div>
                )}
                {initState.imageError && (
                  <p className="text-danger">Image is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openCropper: true,
                        image: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
          x={2}
          y={2}
        />
      )}
    </Container>
  )
}
export default EditCategory
