import React from 'react'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import {
  CardBody,
  Card,
  CardTitle,
  Col,
  Row,
  Button,
  CardFooter,
  Table,
} from 'reactstrap'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import CustomPagnation from 'components/pagination'
import { sendData } from 'utli/apiService'

function Feedback() {
  const router = useHistory()
  const headers = ['#', 'Product Name', 'Product Code', 'Feedback', 'Actions']
  const query = queryString.parse(router.location.search)
  let url = 'feedbacks'
  if (query.search) url += `?keyword=${query.search}`
  const {
    response: { feedbacklist },
    loading,
    error,
  } = useQuery(url)
  const deleteData = async (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.Data cannot be recovered',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`feedbacks/delete/${id}`)
            if (response) window.location.reload()
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardTitle className="pt-3 pl-4 pr-4">
        <Row>
          <Col>
            <h3 className="mb-0">Feedbacks</h3>
          </Col>
        </Row>
      </CardTitle>
      <CardBody>
        <Table responsive className="items-center">
          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th
                    className=" sort"
                    data-sort={element}
                    scope="col"
                    key={`user-account-new-header-${index}`}
                  >
                    {element}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {feedbacklist.data.map((feedback, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{feedback.product.name}</td>
                <td>{feedback.product.code}</td>
                <td>{feedback.feedback}</td>
                <td>
                  <Button
                    className="btn-round btn-icon"
                    color="danger"
                    onClick={() => deleteData(feedback.id)}
                    size="sm"
                  >
                    <span className="btn-inner--text">Delete</span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      {feedbacklist.data.length > 20 && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}

export default Feedback
