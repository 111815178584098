import { useState, useRef } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'

const Setting = () => {
  const {
    handleSubmit,
  } = useForm()

  const {
    response: { info },
    loading,
    error,
  } = useQuery('globalsetting')

  const [initState, setInitState] = useState({
    submitLoading: false,
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })

  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const submitData = async (values) => {
    if (!cropImage && !info?.cover_image) {
      setInitState({ ...initState, imageError: true })
    } else {
      setInitState({ ...initState, submitLoading: true })
      const formData = new FormData()
      if (cropImage) {
        const imageFile = await dataUrlToFile(cropImage)
        formData.append('cover_image', imageFile)
      }

      const response = await sendData('globalsetting/store', formData)
      if (response) {
        NotificationManager.success('Saved')
      }
      setInitState({ ...initState, submitLoading: false })
    }
  }
  
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Setting</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup>
                  <label>Cover Image</label>
                  <br />
                  {cropImage ? (
                    <div>
                      <img
                        alt="croped result"
                        src={cropImage}
                        width="400"
                        height="200"
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      />
                    </div>
                  ) : !info.cover_image ? (
                    <div
                      onClick={() => {
                        inputFile.current.click()
                      }}
                      style={{
                        width: 400,
                        height: 200,
                        background: 'grey',
                        cursor: 'pointer',
                      }}
                    ></div>
                  ) : (
                    <img
                      alt="croped result"
                      src={info.cover_image}
                      width="400"
                      height="200"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  )}
                  {initState.imageError && (
                    <p className="text-danger">Image is required</p>
                  )}
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        setInitState({
                          ...initState,
                          openCropper: true,
                          image: URL.createObjectURL(event.target.files[0]),
                        })
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    {info?.points ? 'Edit' : 'Save'}
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
          x={3.7}
          y={2}
        />
      )}
    </>
  )
}
export default Setting
