import Home from 'pages/Home'
import Login from 'pages/Login'
import User from 'pages/user'
import Category from 'pages/category'
import Notificaton from 'pages/notification'
import Product from 'pages/product'
import CreateProduct from 'pages/product/create'
import EditProduct from 'pages/product/edit'
import CreateCategory from 'pages/category/create'
import EditCategory from 'pages/category/edit'
import CreateUserAccount from 'pages/user/create'
import EditUserAccount from 'pages/user/edit'
import CreateNotification from 'pages/notification/create'
import EditNotification from 'pages/notification/edit'
import ContactInfo from 'pages/contactInfo'
import Setting from 'pages/setting'
import PaymentMethod from 'pages/setting/paymentMethod'
import CreatePaymentMethod from 'pages/setting/paymentMethod/create'
import EditPaymentMethod from 'pages/setting/paymentMethod/edit'
import Orders from 'pages/orders'
import EditOrder from 'pages/orders/edit'
import Admin from 'pages/admin'
import CreateAdminAccount from 'pages/admin/create'
import EditAdminAccount from 'pages/admin/edit'
import RoyalCustomer from 'pages/user/royal'
import UserReport from 'pages/user/report'

import CompanyService from 'pages/companyService'
import CreateCompanyService from 'pages/companyService/create'
import EditCompanyService from 'pages/companyService/edit'

import CompanyClient from 'pages/companyClient'
import CreateCompanyClient from 'pages/companyClient/create'
import EditCompanyClient from 'pages/companyClient/edit'
import TermOfUse from 'pages/termOfUse'
import CompanyInfo from 'pages/companyInfo'
import Feedback from 'pages/feedback'

// import ItemArrival from 'pages/arrival'
// import CreateItemArrival from 'pages/arrival/create'

// import Stock from 'pages/stock'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-primary',
    component: Home,
    layout: '/',
  },
  {
    collapse: true,
    name: 'Users Account',
    icon: 'fas fa-user-friends text-primary',
    state: 'usersCollapse',
    views: [
      {
        path: 'user',
        name: 'List',
        icon: 'fas fa-list text-primary',
        miniName: 'A',
        component: User,
        layout: '/',
      },
      {
        path: 'user/create',
        name: 'Create',
        icon: 'fas fa-user-plus text-primary',
        miniName: 'C',
        component: CreateUserAccount,
        layout: '/',
      },
      {
        path: 'user/edit/:id',
        name: 'EditUser',
        miniName: 'C',
        component: EditUserAccount,
        layout: '/',
        invisible: true,
      },
      {
        path: 'user/royal',
        name: 'RoyalCustomer',
        miniName: 'C',
        component: RoyalCustomer,
        layout: '/',
        invisible: true,
      },
      {
        path: 'user/report',
        icon: 'fas fa-user-plus text-primary',
        name: 'UserReport',
        miniName: 'C',
        component: UserReport,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Admin Account',
    icon: 'fas fa-user-cog text-primary',
    state: 'adminCollapse',
    views: [
      {
        path: 'admin',
        name: 'List',
        icon: 'fas fa-list text-primary',
        miniName: 'A',
        component: Admin,
        layout: '/',
      },
      {
        path: 'admin/store',
        name: 'Create',
        icon: 'fas fa-user-plus text-primary',
        miniName: 'C',
        component: CreateAdminAccount,
        layout: '/',
      },
      {
        path: 'admin/update/:id',
        name: 'EditAdmin',
        miniName: 'C',
        component: EditAdminAccount,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Category',
    icon: 'fas fa-layer-group text-primary',
    state: 'usersCategory',
    views: [
      {
        path: 'category',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Category,
        layout: '/',
      },
      {
        path: 'category/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateCategory,
        layout: '/',
      },
      {
        path: 'category/edit/:id',
        name: 'New',
        icon: 'fas fa-home text-primary',
        component: EditCategory,
        layout: '/',
        invisible: true,
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: 'Inventory',
  //   icon: 'fas fa-boxes text-primary',
  //   state: 'stocksCollapse',
  //   views: [
  //     {
  //       path: 'arrival',
  //       name: 'Item Arrival',
  //       icon: 'fas fa-list text-primary',
  //       component: ItemArrival,
  //       layout: '/',
  //     },
  //     {
  //       path: 'stocks',
  //       name: 'Stock',
  //       icon: 'fas fa-list text-primary',
  //       component: Stock,
  //       layout: '/',
  //     },
  //     {
  //       path: 'arrival/create',
  //       name: 'New',
  //       icon: 'fas fa-plus-circle text-primary',
  //       component: CreateItemArrival,
  //       layout: '/',
  //       invisible: true,
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: 'Product',
    icon: 'fas fa-shopping-bag text-primary',
    state: 'productsCollapse',
    views: [
      {
        path: 'product',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Product,
        layout: '/',
      },
      {
        path: 'product/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateProduct,
        layout: '/',
      },
      {
        path: 'product/edit/:id',
        name: 'Edit',
        icon: 'fas fa-home text-primary',
        component: EditProduct,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    path: 'order',
    name: 'Orders',
    icon: 'fas fa-clipboard-check text-primary',
    component: Orders,
    layout: '/',
  },
  {
    path: 'order/edit/:id',
    name: 'Edit Order',
    icon: 'fas fa-home text-primary',
    component: EditOrder,
    layout: '/',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Annoucement',
    icon: 'fas fa-scroll text-primary',
    state: 'nosCollapse',
    views: [
      {
        path: 'notification',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Notificaton,
        layout: '/',
      },
      {
        path: 'notification/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateNotification,
        layout: '/',
      },
      {
        path: 'notification/edit/:id',
        name: 'Edit',
        icon: 'fas fa-home text-primary',
        component: EditNotification,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    path: 'contactinfo',
    name: 'Contact Info',
    icon: 'far fa-address-book text-primary',
    component: ContactInfo,
    layout: '/',
  },
  {
    path: 'feedbacks',
    name: 'Feedbacks',
    icon: 'far fa-address-book text-primary',
    component: Feedback,
    layout: '/',
  },
  {
    collapse: true,
    path: 'setting',
    name: 'Setting',
    icon: 'fas fa-cogs text-primary',
    views: [
      {
        path: 'global',
        name: 'Global Setting',
        icon: 'fas fa-money-bill-wave text-primary',
        component: Setting,
        layout: '/',
      },
      {
        path: 'paymentMethod',
        name: 'Payment Method',
        icon: 'fas fa-money-bill-wave text-primary',
        component: PaymentMethod,
        layout: '/',
      },
      {
        path: 'paymentMethod/create',
        name: 'Create Payment Method',
        icon: 'fas fa-home text-primary',
        component: CreatePaymentMethod,
        layout: '/',
        invisible: true,
      },
      {
        path: 'paymentMethod/edit/:id',
        name: 'Edit Method',
        icon: 'fas fa-home text-primary',
        component: EditPaymentMethod,
        layout: '/',
        invisible: true,
      },
      {
        path: 'companyService',
        name: 'Company Service',
        icon: 'fas fa-money-bill-wave text-primary',
        component: CompanyService,
        layout: '/',
      },
      {
        path: 'companyService/create',
        name: 'Company Service',
        icon: 'fas fa-money-bill-wave text-primary',
        component: CreateCompanyService,
        layout: '/',
        invisible: true,
      },
      {
        path: 'companyService/edit/:id',
        name: 'Company Service',
        icon: 'fas fa-money-bill-wave text-primary',
        component: EditCompanyService,
        layout: '/',
        invisible: true,
      },
      {
        path: 'companyClient',
        name: 'Company Client',
        icon: 'fas fa-money-bill-wave text-primary',
        component: CompanyClient,
        layout: '/',
      },
      {
        path: 'companyClient/create',
        name: 'Company Client',
        icon: 'fas fa-money-bill-wave text-primary',
        component: CreateCompanyClient,
        layout: '/',
        invisible: true,
      },
      {
        path: 'companyClient/edit/:id',
        name: 'Company Client',
        icon: 'fas fa-money-bill-wave text-primary',
        component: EditCompanyClient,
        layout: '/',
        invisible: true,
      },
      {
        path: 'tnc',
        name: 'Term of Use',
        icon: 'fas fa-money-bill-wave text-primary',
        component: TermOfUse,
        layout: '/',
      },
      {
        path: 'companyInfo',
        name: 'Company Info',
        icon: 'fas fa-money-bill-wave text-primary',
        component: CompanyInfo,
        layout: '/',
      },
    ],
  },
]

export default routes
