import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import useQuery from 'hooks/useQuery'
const EditUserAccount = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    values.id = props.match.params.id
    values.added_admin = true;
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('user/update', values)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
    //window.location.reload()
  }

  const onMakeRoyal = async () => {
    const response = await sendData('users/makeRoyal', {
      id: props.match.params.id,
    })
    if (response) {
      NotificationManager.success('Success')
      window.location.reload()
    }
  }

  const {
    response: { userInfo },
    loading,
    error,
  } = useQuery(`user/detail/${props.match.params.id}`)
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Edit User Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <Row>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('name', {
                          required: 'Name is required',
                        })}
                        defaultValue={userInfo?.name}
                      ></input>
                    </FormGroup>
                    {errors.name && (
                      <span className="text-danger">
                        {errors?.name?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">Shop Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userNameInput"
                        {...register('shopName', {
                          required: 'Shop Name is required',
                        })}
                        defaultValue={userInfo?.shopName}
                      ></input>
                    </FormGroup>
                    {errors.shopName && (
                      <span className="text-danger">
                        {errors?.shopName?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="userNameInput">Type</label>
                      <select
                        className="form-control"
                        {...register('type', {
                          required: 'Type is required',
                        })}
                      >
                        <option value="1">Tea Shop</option>
                        <option value="2">Coffee Shop</option>
                      </select>
                      {errors.type && (
                        <span className="text-danger">
                          {errors?.main_type?.message}
                        </span>
                      )}
                    </FormGroup>
                    {errors.shopName && (
                      <span className="text-danger">
                        {errors?.shopName?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="phoneInput">Phone</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phoneInput"
                        {...register('phone', {
                          required: 'Phone is required',
                        })}
                        defaultValue={userInfo?.phone}
                      ></input>
                      {errors.phone && (
                        <span className="text-danger">
                          {errors?.phone?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="emailInput">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          },
                        })}
                        defaultValue={userInfo?.email}
                      ></input>
                      {errors.email && (
                        <span className="text-danger">
                          {errors?.email?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {/* <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="passwordInput">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        {...register('password', {
                          required: 'Password is required',
                        })}
                      ></input>
                      {errors.password && (
                        <span className="text-danger">
                          {errors?.password?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col> */}
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="addressInput">Address</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="addressInput"
                        {...register('address', {
                          required: 'Address is required',
                        })}
                        defaultValue={userInfo?.address}
                      ></input>
                      {errors.address && (
                        <span className="text-danger">
                          {errors?.address?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">Viber</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="viberInput"
                        {...register('viber', {})}
                        defaultValue={userInfo?.viber}
                      ></input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">Telegram</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="viberInput"
                        {...register('telegram', {})}
                        defaultValue={userInfo?.telegram}
                      ></input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">Township</label>
                      <input
                        type="text"
                        className="form-control"
                        id="viberInput"
                        {...register('township', {})}
                        defaultValue={userInfo?.township}
                      ></input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="viberInput"
                        {...register('city', {})}
                        defaultValue={userInfo?.city}
                      ></input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">State</label>
                      <input
                        type="text"
                        className="form-control"
                        id="viberInput"
                        {...register('state', {})}
                        defaultValue={userInfo?.state}
                      ></input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="submit-form">
                      <label htmlFor="viberInput">Remark</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="viberInput"
                        {...register('remark', {})}
                        defaultValue={userInfo?.remark}
                      ></input>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Edit User
                  </Button>
                </FormGroup> */}
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {userInfo.royal === '0' ? (
        <Button className="btn-warning" onClick={onMakeRoyal}>
          Make Royal Customer
        </Button>
      ) : (
        <Button className="btn-warning" onClick={onMakeRoyal}>
          Undo Royal Customer
        </Button>
      )}
    </>
  )
}
export default EditUserAccount
